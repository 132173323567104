import React, { useEffect, useState } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomeView from '../VIEWS/HomeView';
import AdminView from '../VIEWS/AdminView';
import ImageGallery from '../VIEWS/ImageGallery';
import AuthController from './AuthController';
import Program1 from '../VIEWS/program1';
import Program2 from '../VIEWS/program2';
import Program3 from '../VIEWS/program3';
import Program4 from '../VIEWS/program4';
import Program5 from '../VIEWS/program5';
import Program6 from '../VIEWS/program6';
import Program7 from '../VIEWS/program7';
import Program8 from '../VIEWS/program8';
import Program9 from '../VIEWS/program9';
import Program12 from '../VIEWS/program12';
import Program13 from '../VIEWS/program13';
import Program15 from '../VIEWS/program15';
import Program16 from '../VIEWS/program16';
import Program17 from '../VIEWS/program17';
import Program18 from '../VIEWS/program18';
import Program19 from '../VIEWS/program19';
import Program20 from '../VIEWS/program20';
import Program21 from '../VIEWS/program21';
import Program22 from '../VIEWS/program22';
import Program23 from '../VIEWS/program23';
import Program24 from '../VIEWS/program24';
import Program25 from '../VIEWS/program25';
import Program26 from '../VIEWS/program26';
import Program27 from '../VIEWS/program27';
import ProgramNo from '../VIEWS/programNo';
import EvesProgram from '../VIEWS/evesProgram';
import Users from '../VIEWS/Users';

const RoutingController: React.FunctionComponent = props => {

    useEffect(() => { }, [])

    return(
        <HashRouter>
        <Routes>
            <Route path = "/home" element = { <HomeView /> }></Route>
            <Route path = "/admin" element = { <AdminView /> }></Route>
            <Route path = "/gallery" element = { <ImageGallery /> }></Route>
            <Route path = "/auth" element = { <AuthController /> }></Route>
            <Route path = "/users" element = { <Users /> }></Route>
            <Route path = "/program1" element = { <Program1 /> }></Route>
            <Route path = "/program2" element = { <Program2 /> }></Route>
            <Route path = "/program3" element = { <Program3 /> }></Route>
            <Route path = "/program4" element = { <Program4 /> }></Route>
            <Route path = "/program5" element = { <Program5 /> }></Route>
            <Route path = "/program6" element = { <Program6 /> }></Route>
            <Route path = "/program7" element = { <Program7 /> }></Route>
            <Route path = "/program8" element = { <Program8 /> }></Route>
            <Route path = "/program9" element = { <Program9 /> }></Route>
            <Route path = "/program10" element = { <ProgramNo /> }></Route>
            <Route path = "/program11" element = { <ProgramNo /> }></Route>
            <Route path = "/program12" element = { <Program12 /> }></Route>
            <Route path = "/program13" element = { <Program13 /> }></Route>
            <Route path = "/program15" element = { <Program15 /> }></Route>
            <Route path = "/program16" element = { <Program16 /> }></Route>
            <Route path = "/program17" element = { <Program17 /> }></Route>
            <Route path = "/program18" element = { <Program18 /> }></Route>
            <Route path = "/program19" element = { <Program19 /> }></Route>
            <Route path = "/program20" element = { <Program20 /> }></Route>
            <Route path = "/program21" element = { <Program21 /> }></Route>
            <Route path = "/program22" element = { <Program22 /> }></Route>
            <Route path = "/program23" element = { <Program23 /> }></Route>
            <Route path = "/program24" element = { <Program24 /> }></Route>
            <Route path = "/program25" element = { <Program25 /> }></Route>
            <Route path = "/program26" element = { <Program26 /> }></Route>
            <Route path = "/program27" element = { <Program27 /> }></Route>
            <Route path = "/evesprogram" element = { <EvesProgram /> }></Route>
            <Route path = "/" element = { <HomeView  /> }></Route>

        </Routes>

    </HashRouter>
           
    )

}

export default RoutingController